



































































































import Vue from 'vue';
import { routes } from './router';
export default Vue.extend({
  data: () => ({
    routes
  }),
  computed: {
    mainClass() {
      return {
        'bg-img': this.$route.name === 'Home',
        [`img-${this.$vuetify.breakpoint.name}`]: true
      };
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
});
