









































import Vue from 'vue';
import ResizeObserver from 'resize-observer-polyfill';
export default Vue.extend({
  props: ['avatar', 'name', 'title', 'employer', 'education', 'experience'],
  data: () => ({
    observer: null as null | ResizeObserver,
    horizontal: false
  }),
  computed: {
    avatarSize(): number {
      return this.horizontal ? 72 : 96;
    },
    classes(): Record<string, boolean> {
      return {
        profile: true,
        'profile-horizontal': this.horizontal
      };
    }
  },
  mounted() {
    this.setHorizontal(this.$el.getClientRects()[0]);
    this.observer = new ResizeObserver((entries) => {
      if (!entries.length) return;
      this.setHorizontal(entries[0].contentRect);
    });
    this.observer.observe(this.$el);
  },
  beforeDestroy() {
    this.observer?.unobserve(this.$el);
  },
  methods: {
    setHorizontal({ width }: Partial<DOMRectReadOnly>) {
      this.horizontal = (width ?? 501) > 500;
    }
  }
});
