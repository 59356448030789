




































import Vue from 'vue';
import { Cam16, Hct, Hex, Rgb } from '../utils/color';

const palettes: Record<string, string[][]> = { dark: [], light: [] };
[
  ['#fa371a'],
  ['#f27d23'],
  ['#f9c914'],
  ['#42ceb6'],
  ['#2baef5'],
  ['#6460ff']
].forEach(([el]) => {
  const argb = new Hex(el).toRgb().toInt();
  const { hue, chroma } = Cam16.fromInt(argb);
  palettes.light.push([
    Rgb.fromInt(new Hct(hue, chroma, 40).toInt()).toHex().toString()
  ]);
  palettes.dark.push([
    Rgb.fromInt(new Hct(hue, chroma, 80).toInt()).toHex().toString()
  ]);
});
export default Vue.extend({
  data: () => ({
    menu: false,
    color: '#6460ff',
    swatches: [
      ['#fa371a'],
      ['#f27d23'],
      ['#f9c914'],
      ['#42ceb6'],
      ['#2baef5'],
      ['#6460ff']
    ]
  }),
  watch: {
    color: {
      immediate: true,
      handler: function (val) {
        this.setTheme(val);
      }
    }
  },
  methods: {
    setTheme(val: string): void {
      const argb = new Hex(val).toRgb().toInt();
      const { hue, chroma } = Cam16.fromInt(argb);
      this.setThemeKey(hue, Math.max(48, chroma), 'primary');
      this.setThemeKey(hue, Math.max(48, chroma), 'success');
      this.setThemeKey(hue, 16, 'secondary');
      this.setThemeKey(hue + 60, 24, 'accent');
      this.setThemeKey(hue, 4, 'info');
      this.setThemeKey(hue, 8, 'warning');
      this.setThemeKey(25, 84, 'error');
    },
    setThemeKey(hue: number, chroma: number, key: string | number): void {
      const { dark, light } = this.$vuetify.theme.themes;
      dark[key] = Rgb.fromInt(new Hct(hue, chroma, 80).toInt())
        .toHex()
        .toString();
      light[key] = Rgb.fromInt(new Hct(hue, chroma, 40).toInt())
        .toHex()
        .toString();
    }
  }
});
